import Container from 'app/components/Container'
import DoubleGlowShadow from 'app/components/DoubleGlowShadow'
import React, { FC } from 'react'

import DefaultLayout from './Default'

export interface Layout {
  id: string
}

export const SwapLayoutCard: FC = ({ children }) => {
  return (
    <div
      style={{
        background:
          'linear-gradient(#171616 0 0) padding-box,linear-gradient(135deg,  #f9560a 0%, #ffb800 18%, #52a414 36%, #00aabd 54%, #224e8f 72%, #722378 89%, #c4074d 100%) border-box',
        border: '2px solid transparent',
        borderRadius: '10px',
      }}
      className="flex flex-col gap-3 p-2 md:p-4 pt-4"
    >
      {children}
    </div>
  )
}

export const Layout: FC<Layout> = ({ children, id }) => {
  return (
    <DefaultLayout>
      <Container id={id} className="py-4 md:py-12 lg:py-[120px] px-2" maxWidth="md">
        <DoubleGlowShadow>{children}</DoubleGlowShadow>
      </Container>
    </DefaultLayout>
  )
}

type SwapLayout = (id: string) => FC
export const SwapLayout: SwapLayout = (id: string) => {
  return (props) => <Layout id={id} {...props} />
}
